<div class="row">
<div class="col-lg-12 col-xl-12 stretch-card" id="min_max">
    <header class="d-none">
        <div class="row">
    
            <div class="col-sm">
                <img width="180" src="../../../../assets/images/TEB_Logo 7.jpg" >
            </div>
    
            <div class="col-sm d-flex justify-content-end">
                <h4>{{ user?.company }}</h4>
            </div>
        </div>
    
        <div class="row" style="margin-top: -10px;">
           
            <div class="col-sm d-flex justify-content-end">
                <h6>{{ curr_date }}</h6>
            </div>
        </div>
    </header>
    <div class="row flex-grow">

        <div class="d-grid col grid-margin stretch-card">
            <div class="card">
                <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
                    <h6 class="card-title mb-0 ">Minimum and Maximum Values / {{location}}</h6>

                    <div class="d-flex align-items-center justify-content-end">
                        <button class="btn mr-1 pr-1" type="button" data-toggle="collapse"
                        data-target="#collapseExample" aria-expanded="false"
                        aria-controls="collapseExample">
                        <i class="feather-sliders"
                            style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                        </button>
                        <div class="dropdown ml-2">
                            <button class="btn p-0" type="button" id="dropdownMenuButton3" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                            </button>
                            <div id="hideprint" class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                <button [useExistingCss]="true" printSectionId="min_max" ngxPrint type="button"
                                class="dropdown-item d-flex align-items-center" 
                                    name="Print" >
                                    <i class="feather feather-printer icon-sm mr-2"></i>
                                    <span class="">Print</span>
                                </button>
                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                    (click)="exportAsCSV()">
                                    <i class="feather feather-download icon-sm mr-2"></i>
                                    <span class="">Export as CSV</span>
                                </a>
                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Pdf"
                                    (click)="data_param_el = 'min_max'; exportAsPDF($event)">
                                    <i class="feather feather-file-text icon-sm mr-2"></i>
                                    <span class="">Export as PDF</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="collapse px-2" id="collapseExample">
                    <div class="card-body overflow-auto">
                        <div class="w-max-content header-right-inner-left d-flex"> 
                            <div class="form-group mb-0 mr-2">
                                <h6>Select Date:</h6>
                            </div>   
                            <div class="form-group mb-0 mr-2">
                                <input type="date" [(ngModel)]="date" autocomplete="off" class="form-control"
                                    (change)="onDateSelection($event)">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mr-0 card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered">
                            <thead>
                                <tr id="table-head" style="text-align: center; color: white; background-color: #073C5C;">
                                    <th style="color: white;" width="20%"><b>Parameters</b></th>
                                    <th style="color: white;" width="10%"><b>Unit</b></th>
                                    <th style="color: white;" width="10%"><b>Min. Value</b></th>
                                    <th style="color: white;" width="15%"><b>Min. Time</b></th>
                                    <th style="color: white;" width="10%"><b>Max. Value</b></th>
                                    <th style="color: white;" width="15%"><b>Max. Time</b></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>Phase Voltage V1-N</b></td>
                                    <td style="text-align: center;"><b>Volt</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_rn_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_rn_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_rn_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_rn_max_date }}</b></td>
                                </tr>

                                <tr style="color: black; background-color: rgb(228, 228, 228);">
                                    <td style="text-align: center;"><b>Phase Voltage V2-N</b></td>
                                    <td style="text-align: center;"><b>Volt</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_yn_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_yn_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_yn_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_yn_max_date }}</b></td>
                                </tr>

                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>Phase Voltage V3-N</b></td>
                                    <td style="text-align: center;"><b>Volt</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_bn_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_bn_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_bn_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_bn_max_date }}</b></td>
                                </tr>

                                <tr style="color: black; background-color: rgb(228, 228, 228);">
                                    <td style="text-align: center;"><b>Line Voltage V-12</b></td>
                                    <td style="text-align: center;"><b>Volt</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_ry_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_ry_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_ry_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_ry_max_date }}</b></td>
                                </tr>

                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>Line Voltage V-23</b></td>
                                    <td style="text-align: center;"><b>Volt</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_yb_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_yb_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_yb_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_yb_max_date }}</b></td>
                                </tr>

                                <tr style="color: black; background-color: rgb(228, 228, 228);">
                                    <td style="text-align: center;"><b>Line Voltage V-31</b></td>
                                    <td style="text-align: center;"><b>Volt</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_br_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_br_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_br_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_br_max_date }}</b></td>
                                </tr>


                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>AVG Phase Voltage VL-L </b></td>
                                    <td style="text-align: center;"><b>Volt</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_l_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ vl_l_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_l_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ vl_l_max_date }}</b></td>

                                </tr>

                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>Line Current L1</b></td>
                                    <td style="text-align: center;"><b>Ampere</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ curr_r_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ curr_r_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ curr_r_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ curr_r_max_date }}</b></td>
                                </tr>

                                <tr style="color: black; background-color: rgb(228, 228, 228);">
                                    <td style="text-align: center;"><b>Line Current L2</b></td>
                                    <td style="text-align: center;"><b>Ampere</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ curr_y_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ curr_y_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ curr_y_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ curr_y_max_date }}</b></td>
                                </tr>

                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>Line Current L3</b></td>
                                    <td style="text-align: center;"><b>Ampere</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ curr_b_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ curr_b_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ curr_b_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ curr_b_max_date }}</b></td>
                                </tr>

                                <tr style="color: black; background-color: rgb(228, 228, 228);">
                                    <td style="text-align: center;"><b>Avg. 3 Phase Current</b></td>
                                    <td style="text-align: center;"><b>Ampere</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ curr_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ curr_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ curr_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ curr_max_date }}</b></td>
                                </tr>

                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>Power Factor 3 Phase (Avg)</b></td>
                                    <td style="text-align: center;"><b>-</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ pf_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ pf_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ pf_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ pf_max_date }}</b></td>
                                </tr>

                                <tr style="color: black; background-color: rgb(228, 228, 228);">
                                    <td style="text-align: center;"><b>Frequency</b></td>
                                    <td style="text-align: center;"><b>Hz</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{
                                            frequency_min.toFixed(2) }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ frequency_min_date
                                            }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ frequency_max.toFixed(2) }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ frequency_max_date }}</b></td>
                                </tr>

                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>Active Power L1</b></td>
                                    <td style="text-align: center;"><b>KW</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ w_r_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ w_r_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ w_r_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ w_r_max_date }}</b></td>
                                </tr>

                                <tr style="color: black; background-color: rgb(228, 228, 228);">
                                    <td style="text-align: center;"><b>Active Power L2</b></td>
                                    <td style="text-align: center;"><b>KW</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ w_y_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ w_y_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ w_y_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ w_y_max_date }}</b></td>
                                </tr>

                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>Active Power L3</b></td>
                                    <td style="text-align: center;"><b>KW</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ w_b_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ w_b_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ w_b_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ w_b_max_date }}</b></td>
                                </tr>

                                <tr style="color: black; background-color: rgb(228, 228, 228);">
                                    <td style="text-align: center;"><b>Total Active Power</b></td>
                                    <td style="text-align: center;"><b>KW</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ tot_min.toFixed(2)
                                            }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ tot_min_date }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ tot_max.toFixed(2) }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ tot_max_date }}</b></td>

                                </tr>

                                <tr style="color: black;">
                                    <td style="text-align: center;"><b>Total Apparent Power</b></td>
                                    <td style="text-align: center;"><b>KVA</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{
                                            total_va_min.toFixed(2) }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ total_va_min_date
                                            }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ total_va_max.toFixed(2) }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ total_va_max_date }}</b></td>

                                </tr>

                                <tr style="color: black; background-color: rgb(228, 228, 228);">
                                    <td style="text-align: center;"><b>Total Reactive Power</b></td>
                                    <td style="text-align: center;"><b>KVAR</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{
                                            total_var_min.toFixed(2) }}</b></td>
                                    <td style="color: rgb(30, 182, 0); text-align: center;"><b>{{ total_var_min_date
                                            }}</b></td>
                                    <td style="color: red; text-align: center;"><b>{{ total_var_max.toFixed(2) }}</b>
                                    </td>
                                    <td style="color: red; text-align: center;"><b>{{ total_var_max_date }}</b></td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<!-- row -->