<div class="row mt-4">

    <div class="col-lg-12 col-xl-12 stretch-card" id="tab_real_time_daily_pv_solar_generation_report">
        <div class="d-grid row flex-grow">

            <div class="col grid-margin stretch-card" id="report_table">
                <div class="card" id="tab-table">
                    <div class="d-flex justify-content-between card-header align-items-center mb-md-3">
                        <h4 class="card-title mb-0" style="color: red;">Real Time PV-Solar Generation Report</h4>

                        <div class="d-flex align-items-center justify-content-end">

                            <button class="btn pr-2" type="button" data-toggle="collapse"
                                data-target="#collapsePowerTrend" aria-expanded="false"
                                aria-controls="collapsePowerTrend">
                                <i class="feather-sliders"
                                    style="font-size:x-large; color: #ff0000;; font-weight: 500;"></i>
                            </button>


                            <div class="dropdown ml-2">
                                <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                    <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                        name="Print"
                                        (click)="data_param_el = 'report_table'; exportAsPDF($event)">
                                        <i class="icon-sm mr-2 feather feather-printer"></i>
                                        <span class="">Print</span>
                                    </a>
                                    <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                        (click)="exportAsCSV()">
                                        <i class="icon-sm mr-2 feather feather-download"></i>
                                        <span class="">Export as CSV</span>
                                    </a>
                                    <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                        name="Pdf"
                                        (click)="data_param_el = 'report_table'; exportAsPDF($event)">
                                        <i class="icon-sm mr-2 feather feather-file-text"></i>
                                        <span class="">Export as PDF</span>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="collapse px-2" id="collapsePowerTrend">
                        <div class="card-body overflow-auto">
                            <div class="w-max-content header-right-inner-left d-flex">
                                <h6 class="text-nowrap mr-2">
                                    Date select:
                                </h6>

                                    <input type="text" class="form-control dateInputresponsive" (change)="dateSelection(selected)"
                                    style="width: 236px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                    ngxDaterangepickerMd [(ngModel)]="selected" startKey="start"
                                    endKey="end" [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"
                                    [customRangeDirection]="true"
                                    [locale]="{ displayFormat: 'DD MMM YYYY HH', separator: '    To    ',  cancelLabel: 'Cancel'}"
                                    [showClearButton]="true" [showCancel]="true" [showWeekNumbers]="true"
                                    [ranges]="ranges" [linkedCalendars]="true" placeholder="Select Range">
                                    <div class="form-group mb-0">

                                        <button type="button" class="btn" (click)="onClick()">
                                            <i class="feather-eye"
                                                style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                                        </button>
                                    </div>

                            </div>
                        </div>
                    </div>

                    <div id="Table-print" class="card-body">
                        <header style="display: none;">
                            <div class="row">

                                <div class="col-sm">
                     
                                </div>

                                <div class="col-sm d-flex justify-content-end">
                                    <!-- <h4>{{ user?.company }}</h4> -->
                                </div>
                            </div>

                            <div class="row" style="margin-top: -10px;">

                                <div class="col-sm d-flex justify-content-end">
                                    <!-- <h6>{{ curr_date }}</h6> -->
                                </div>
                            </div>
                        </header>
                        <h4 id="card-title" class="card-title mb-0 text-left" style="display: none; color: red;">Real
                            Time PV-Solar Generation Report</h4>
                        <div class="table-responsive text-nowrap">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="text-align: center;">Location</th>
                                        <th style="text-align: center;">Status </th>
                                        <th style="text-align: center;">Installation date</th>
                                        <th style="text-align: center;">Total PV Capacity (KW)</th>
                                        <th style="text-align: center;">Expected Kwh</th>
                                        <th style="text-align: center;">Actual Generation (KWH)</th>
                                        <th style="text-align: center;">Diffrence (KWH)</th>
                                        <th style="text-align: center;">time stamp</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr *ngFor="let data of pv_genration_report">
                                        <td style="text-align: center;">{{ data.location }}</td>
                                        <td style="text-align: center;">{{ data.status ? 'Active' : 'Inactive' }}</td>
                                        <td style="text-align: center;">{{ data.installation_date }}</td>
                                        <td style="text-align: center;">{{ data.total_pv_capacity_kw | currency: ' ' }}
                                        </td>
                                        <td style="text-align: center;">{{ data.expected_kwh_per_day | currency: ' ' }}
                                        </td>
                                        <td style="text-align: center;">{{ data.today_generation.toFixed(2) | currency:
                                            ' ' }}</td>
                                        <td style="text-align: center;"
                                            [ngClass]="{'text-danger': data.difference < 0, 'text-success': data.difference >= 0}">
                                            {{ data.difference.toFixed(2) | currency: ' ' }}</td>
                                        <td style="text-align: center;" *ngIf="data.timestamp != ''">{{ data.timestamp |
                                            date: 'medium' }}</td>
                                        <td style="text-align: center;" *ngIf="data.timestamp == ''">{{ '--------' }}
                                        </td>
                                    </tr>


                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div> <!-- row -->