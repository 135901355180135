<zainergy-dashboard-cards *ngIf="energy_status" for="analytics"></zainergy-dashboard-cards>
<zainergy-dashboard-cards *ngIf="flow_status" for="flow"></zainergy-dashboard-cards>

<div id="power-trend">
    <header>
        <div class="row">

            <div class="col-sm">
                <img width="250" src="../../../../assets/images/TEB_Logo 7.jpg">
            </div>

            <div class="col-sm d-flex justify-content-end">
                <h3>{{ user?.company }}</h3>
            </div>
        </div>

        <div class="row" style="margin-top: -10px;">

            <div class="col-sm d-flex justify-content-end">
                <h5>{{ curr_date }}</h5>
            </div>
        </div>
    </header>
    <div class="row">

        <div id="first-row" class="d-grid col-md-12 col-xl-12 grid-margin stretch-card">
            <div class="card">
                <div class="d-flex justify-content-between card-header align-items-center mb-md-3 overflow-auto" id="chart_total">
                    <h6 class="card-title mb-0 ">{{title}} / {{location}}</h6>

                    <div class="header-right d-flex">

                        <p>
                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseExample"
                                aria-expanded="false" aria-controls="collapseExample">
                                <i class="feather-sliders"
                                    style="font-size: x-large; color: #ff0000;; font-weight: 500;"></i>
                            </button>
                        </p>

                        <a href="javascript:void(0);" (click)="tabLineChart('line')" class="graph pt-2">
                            <span [ngClass]="{'active': chart_active == 'line'}"><i
                                    class="icon-sm card-header-icon mr-2 pb-3px feather feather-trending-up"></i></span>
                        </a>

                        <span class="divider mt-2"></span>

                        <a href="javascript:void(0);" (click)="tabBarChart('bar')" class="graph ml-1 pt-2">
                            <span [ngClass]="{'active': chart_active == 'bar'}"><i
                                    class="icon-sm card-header-icon mr-2 pb-3px feather feather-bar-chart-2"></i></span>
                        </a>

                        <i class="icon-sm card-header-icon ml-1 pt-2 pb-3px maximize-card feather feather-maximize-2"
                            (click)="toggleCard($event)"></i>

                        <div id="print-hide" class="dropdown mt-1">
                            <button class="btn p-0" type="button" id="dropdownMenuButton3" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                <button [useExistingCss]="true" printSectionId="power-trend" ngxPrint type="button"
                                    class="dropdown-item d-flex align-items-center" name="Print">
                                    <i class="feather feather-printer icon-sm mr-2"></i>
                                    <span class="">Print</span>
                                </button>
                                <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Pdf"
                                    (click)="data_param = 'total'; data_param_el = 'chart_total'; exportAsPDF($event)">
                                    <i class="feather feather-file-text icon-sm mr-2"></i>
                                    <span class="">Export as PDF</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="collapse overflow-auto" id="collapseExample">
                    <div class="p-3 stretch-card w-max-content">

                        <div class="w-100 header-right-inner-left d-flex">

                            <div *ngIf="energy_status" class="form-group mb-0 mr-2">
                                <h6>Parameters:</h6>
                            </div>

                            <div *ngIf="energy_status" class="form-group mb-0 mr-2">
                                <ng-multiselect-dropdown [placeholder]="'Select parameters'"
                                    [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
                                    (onSelect)="onItemSelectTrend($event)" (onDeSelect)="onItemDeSelectTrend($event)">
                                </ng-multiselect-dropdown>
                            </div>

                            <div class="form-group mr-2 mb-0 text-nowrap">
                                <h6>Select Range:</h6>
                            </div>

                            <div class="form-group mr-4 mb-0">

                                <input type="text" class="form-control" (change)="dateSelection(selected)"
                                    style="width: 325px; height: 36px; border:  1px solid rgb(204, 204, 204); border-radius: 5px 5px 5px 5px; color: red;"
                                    ngxDaterangepickerMd [(ngModel)]="selected" startKey="start" [timePicker]="true"
                                    [timePickerSeconds]="false" [timePicker24Hour]="true" endKey="end"
                                    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true"
                                    [customRangeDirection]="true"
                                    [locale]="{ displayFormat: 'DD MMM YYYY  HH:00', separator: '    To    ',  cancelLabel: 'Cancel', firstDay: 1}"
                                    [showClearButton]="true" [showCancel]="true" [showWeekNumbers]="true"
                                    [ranges]="ranges" [linkedCalendars]="true" placeholder="Select Range">

                            </div>

                            <div class="form-group mb-0 mr-2 text-nowrap">
                                <h6>Graph Type:</h6>
                            </div>

                            <div class="form-group mb-0 mr-2">
                                <select style="width:8em;" *ngIf="energy_status" class="form-select" aria-label="Default select example"
                                    [(ngModel)]="graph_type" (change)="DropdownSetting()">
                                    <option disabled>Select Graph Type</option>
                                    <option value="all">All</option>
                                    <option value="val_" selected>Average</option>
                                    <option value="min_">Minimum</option>
                                    <option value="max_">Maximum</option>
                                </select>

                                <select style="width:8em;" *ngIf="flow_status" class="form-select" aria-label="Default select example"
                                    [(ngModel)]="graph_type">
                                    <option disabled>Select Graph Type</option>
                                    <option value="all">All</option>
                                    <option value="val_" selected>Average</option>
                                    <option value="min_">Minimum</option>
                                    <option value="max_">Maximum</option>
                                </select>
                            </div>

                            <div class="form-group mb-0 ml-2 mr-2 text-nowrap">
                                <h6>Fixed Interval:</h6>
                            </div>

                            <div class="form-group mb-0 mr-2">
                                <select style="width:8em;" class="form-select" aria-label="Default select example"
                                    [(ngModel)]="fixed_interval">
                                    <option disabled>Select Fixed Interval</option>
                                    <option value="1s">1 Second</option>
                                    <option value="1m">1 Minute</option>
                                    <option value="5m" selected>5 Minute</option>
                                    <option value="15m">15 Minute</option>
                                    <option value="30m">30 Minute</option>
                                    <option value="1h">1 Hour</option>
                                </select>
                            </div>

                            <div class="form-group mb-0">

                                <button type="button" class="btn" (click)="onClick()">
                                    <i class="feather-eye"
                                        style="font-size: x-large; color: #ff0000; font-weight: 500;"></i>
                                </button>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="card-body">

                    <div class="flot-wrapper">
                        <div id="chartTotalW" style="width: 100%; height: 500px"></div>
                        <div class="row">

                            <div class="col">

                                <!-- power  -->
                                <h6 class="{{min.total_w_class}} text-success">
                                    Total Power min : {{ min.total_w_value.toFixed(2) }} ({{ min.total_w_timestamp | date:'short' }})
                                </h6>

                                <h6 class="{{max.total_w_class}} text-danger">
                                    Total Power max : {{ max.total_w_value.toFixed(2) }} ({{ max.total_w_timestamp | date:'short' }})
                                </h6>

                            </div>

                            <div class="col {{min.w_r_class}}">

                                <!-- l1  -->
                                <h6 class="{{min.w_r_class}} text-success">
                                    Active Power L1 min : {{ min.w_r_value.toFixed(2) }} ({{ min.w_r_timestamp | date:'short' }})
                                </h6>

                                <h6 class="{{max.w_r_class}} text-danger">
                                    Active Power L1 max : {{ max.w_r_value.toFixed(2) }} ({{ max.w_r_timestamp | date:'short' }})
                                </h6>

                            </div>

                            <div class="col {{min.w_y_class}}">

                                <!-- l2  -->
                                <h6 class="{{min.w_y_class}} text-success">
                                    Active Power L2 min : {{ min.w_y_value.toFixed(2) }} ({{ min.w_y_timestamp | date:'short' }})
                                </h6>

                                <h6 class="{{max.w_y_class}} text-danger">
                                    Active Power L2 max : {{ max.w_y_value.toFixed(2) }} ({{ max.w_y_timestamp | date:'short' }})
                                </h6>

                            </div>

                            <div class="col {{min.w_b_class}}">

                                <!-- l3  -->
                                <h6 class="{{min.w_b_class}} text-success">
                                    Active Power L3 min : {{ min.w_b_value.toFixed(2) }} ({{ min.w_b_timestamp | date:'short' }})
                                </h6>

                                <h6 class="{{max.w_b_class}} text-danger">
                                    Active Power L3 max : {{ max.w_b_value.toFixed(2) }} ({{ max.w_b_timestamp | date:'short' }})
                                </h6>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row" id="power-trend-table">

        <div class="col-lg-12 col-xl-12 stretch-card">
            <div class="row flex-grow">

                <div class="d-grid col grid-margin stretch-card" id="tab_power_trend_table">
                    <div class="card">
                        <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3"
                            id="tabular">
                            <h6 class="card-title mb-0 ">Table

                            </h6>

                            <div class="d-flex align-items-center justify-content-end">

                                <div class="dropdown ml-2">
                                    <button class="btn p-0" type="button" id="dropdownMenuButton3"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                        <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                            (click)="data_param = 'table'; data_param_el = 'tab_power_trend_table'; exportAsCSV()">
                                            <i class="feather feather-download icon-sm mr-2"></i>
                                            <span class="">Export as CSV</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr style="text-align: center;">
                                            <th *ngFor="let param of params">{{
                                                getTitleByParamCode(param) }}
                                            </th>
                                            <th>Timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style="text-align: center;" *ngFor="let data of tabular_data">
                                            <td *ngFor="let param of params">{{ (data[param]) ?
                                                data[param].toFixed(2) : '0.00' }}</td>
                                            <td>{{ data['timestamp'] }}
                                                <!-- {{ data['@timestamp'] | date:'EEEE, MMMM d, yyyy hh:mm:ss a' }} -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br>
                            <div *ngIf="show_pagination">

                                <ngb-pagination class="d-flex justify-content-end" [collectionSize]="total_found"
                                    [(page)]="page" [maxSize]="2" [rotate]="true" [pageSize]="pageSize"
                                    [boundaryLinks]="true" (pageChange)="loadPage($event)">
                                </ngb-pagination>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>