import { Component, OnInit, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
// amCharts imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import { enPdf } from 'src/app/shared/constant';

@Component({
  selector: 'app-chart-live-data',
  templateUrl: './chart-live-data.component.html',
  styleUrls: ['./chart-live-data.component.scss']
})
export class ChartLiveDataComponent implements OnInit {

  private chart: am4charts.XYChart;
  public data_param_el: String = '';
  public data:any =[];
  
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private dataShared: DataSharedService) {
  }

  ngOnInit(): void {

    this.getSingleDeviceGraph();

  }
  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getSingleDeviceGraph() {
    // Chart code goes in here
    this.browserOnly(() => {

      this.getChartData();

    });
  }
  getChartData() {
    let prop = this.getGraphProp('data', this.data);
    this.implementChart();
  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      title: '',
      data: param_data,
      interval: {
        'interval': 'minute',
        'value': 5
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };

    if (param == 'data') {
      data.element = 'livechartdiv'
      data.title = 'BAR'
    }

    return data;
  }

  implementChart() {

    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create("livechartdiv", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0;
    
    chart.padding(0, 0, 0, 0);
    
    chart.zoomOutButton.disabled = true;
    
    this.data = [];
    let visits = 10;
    let i = 0;
    
    for (i = 0; i <= 30; i++) {
        visits -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        this.data.push({ date: new Date().setSeconds(i - 30), value: visits });
        
      }
    
    chart.data = this.data;
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.dateFormats.setKey("second", "ss");
    dateAxis.periodChangeDateFormats.setKey("second", "[bold]h:mm a");
    dateAxis.periodChangeDateFormats.setKey("minute", "[bold]h:mm a");
    dateAxis.periodChangeDateFormats.setKey("hour", "[bold]h:mm a");
    dateAxis.renderer.inside = true;
    dateAxis.renderer.axisFills.template.disabled = true;
    dateAxis.renderer.ticks.template.disabled = true;
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.interpolationDuration = 500;
    valueAxis.rangeChangeDuration = 500;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.minLabelPosition = 0.05;
    valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.interpolationDuration = 500;
    series.defaultState.transitionDuration = 0;
    series.tensionX = 0.8;
    
    chart.events.on("datavalidated", function () {
        dateAxis.zoom({ start: 1 / 15, end: 1.2 }, false, true);
    });
    
    dateAxis.interpolationDuration = 500;
    dateAxis.rangeChangeDuration = 500;
    
    document.addEventListener("visibilitychange", function() {
        if (document.hidden) {
            if (interval) {
                clearInterval(interval);
            }
        }
        else {
            startInterval();
        }
    }, false);
    
    // add data
    let interval;
    function startInterval() {
        interval = setInterval(function() {
            visits =
            visits + Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 5);
            let lastdataItem = series.dataItems.getIndex(series.dataItems.length - 1);
            chart.addData(
                { date: new Date(lastdataItem.dateX.getTime() + 1000), value: visits },
                1
            );
        }, 1000);
    }
    
    startInterval();
    
    // all the below is optional, makes some fancy effects
    // gradient fill of the series
    series.fillOpacity = 1;
    let gradient = new am4core.LinearGradient();
    gradient.addColor(chart.colors.getIndex(0), 0.2);
    gradient.addColor(chart.colors.getIndex(0), 0);
    series.fill = gradient;
    
    // this makes date axis labels to fade out
    dateAxis.renderer.labels.template.adapter.add("fillOpacity", function (fillOpacity, target) {
        let dataItem = target.dataItem;
        return dataItem.position;
    })
    
    // need to set this, otherwise fillOpacity is not changed and not set
    dateAxis.events.on("validated", function () {
        am4core.iter.each(dateAxis.renderer.labels.iterator(), function (label) {
            label.fillOpacity = label.fillOpacity;
        })
    })
    
    // this makes date axis labels which are at equal minutes to be rotated
    dateAxis.renderer.labels.template.adapter.add("rotation", function (rotation, target) {
        let dataItem = target.dataItem;
        if (dataItem) {
            target.verticalCenter = "middle";
            target.horizontalCenter = "left";
            return -90;
        }
        else {
            target.verticalCenter = "bottom";
            target.horizontalCenter = "middle";
            return 0;
        }
    })
    
    // bullet at the front of the line
    let bullet = series.createChild(am4charts.CircleBullet);
    bullet.circle.radius = 5;
    bullet.fillOpacity = 1;
    bullet.fill = chart.colors.getIndex(0);
    bullet.isMeasured = false;
    
    series.events.on("validated", function() {
        bullet.moveTo(series.dataItems.last.point);
        bullet.validatePosition();
    });

  }
 exportAsCSV() {

    let data = this.data;

    if (data) {
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);

      let csv = data.map(row => header.map(field_name => JSON.stringify(row[field_name], replacer)).join(','));
      csv.unshift(header.join(',').toUpperCase());

      let csv_array = csv.join('\r\n');

      var blob = new Blob([csv_array], { type: 'text/csv' })

  
    }
  }

  exportAsPDF(e) {
    let data = this.data
    if (data) {

      const header = Object.keys(data[0]);
      let csv = []

      data.map((i) => {
        csv.push(Object.values(i))
      })

      let graph_header = []
      graph_header.push(header)
      let pdf_config = new jsPDF();
      pdf_config.setFontSize(18);
      pdf_config.text(`${this.data_param_el}`, 11, 8);
      pdf_config.setFontSize(11);
      pdf_config.setTextColor(100);

      (pdf_config as any).autoTable({
        head: graph_header,
        body: csv,
        theme: 'striped',
      })

      // generate graph on pdf
      this.generatePrintPdf(pdf_config, e)
    }
  }

  generatePrintPdf(pdf_config, e) {
    let finalY = (pdf_config as any).lastAutoTable.finalY;
    let data = document.getElementById(`${this.data_param_el}`)

    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 195;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pageHeight = pdf_config.internal.pageSize.height;

      let spaceLeft = pageHeight - finalY

      if (spaceLeft < imgHeight) {
        pdf_config.addPage()
        finalY = 0
      }

      pdf_config.addImage(contentDataURL, 'PNG', 15, finalY + 3, imgWidth, imgHeight)

      if (e.target.name == enPdf.print) {
        pdf_config.autoPrint();
        window.open(pdf_config.output('bloburl'), '_blank');
      } else pdf_config.save(`${this.data_param_el}.pdf`);

    })
  }
  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
