import { Component, OnInit, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import { enPdf } from 'src/app/shared/constant';

@Component({
  selector: 'app-linechart-data-grouping',
  templateUrl: './linechart-data-grouping.component.html',
  styleUrls: ['./linechart-data-grouping.component.scss']
})
export class LinechartDataGroupingComponent implements OnInit {

  private chart: am4charts.XYChart;
  public data: any = [];
  public data_param_el: String = '';
  
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private dataShared: DataSharedService) {
  }

  ngOnInit(): void {
    this.getSingleDeviceGraph();
  }
  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getSingleDeviceGraph() {
    // Chart code goes in here
    this.browserOnly(() => {

      this.getChartData();

    });
  }
  getChartData() {

    this.data = [];
    let visits = 10;
    for (var i = 1; i < 50000; i++) {
      visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
      this.data.push({ date: new Date(2018, 0, i), value: visits });
    }

    let prop = this.getGraphProp('data', this.data);

    this.implementChart(prop);

  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      title: '',
      data: param_data,
      interval: {
        'interval': 'minute',
        'value': 5
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };

    if (param == 'data') {
      data.element = 'chartdiv'
      data.title = 'BAR'
    }

    return data;
  }

  implementChart(data) {

    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    // Add data
    chart.data = data.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.minZoomCount = 5;


    // this makes the data to be grouped
    dateAxis.groupData = true;
    dateAxis.groupCount = 500;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.tooltipText = "{valueY}";
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.background.fillOpacity = 0.5;

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    let scrollbarX = new am4charts.XYChartScrollbar();
    scrollbarX.series.push(series);
    chart.scrollbarX = scrollbarX;
  }

  exportAsCSV() {

    let data = this.data;

    if (data) {
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);

      let csv = data.map(row => header.map(field_name => JSON.stringify(row[field_name], replacer)).join(','));
      csv.unshift(header.join(',').toUpperCase());

      let csv_array = csv.join('\r\n');

      var blob = new Blob([csv_array], { type: 'text/csv' })

      
    }
  }

  exportAsPDF(e) {
    let data = this.data
    if (data) {

      const header = Object.keys(data[0]);
      let csv = []

      data.map((i) => {
        csv.push(Object.values(i))
      })

      let graph_header = []
      graph_header.push(header)
      let pdf_config = new jsPDF();
      pdf_config.setFontSize(18);
      pdf_config.text(`${this.data_param_el}`, 11, 8);
      pdf_config.setFontSize(11);
      pdf_config.setTextColor(100);

      (pdf_config as any).autoTable({
        head: graph_header,
        body: csv,
        theme: 'striped',
      })

      // generate graph on pdf
      this.generatePrintPdf(pdf_config, e)
    }
  }

  generatePrintPdf(pdf_config, e) {
    let finalY = (pdf_config as any).lastAutoTable.finalY;
    let data = document.getElementById(`${this.data_param_el}`)

    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 195;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pageHeight = pdf_config.internal.pageSize.height;

      let spaceLeft = pageHeight - finalY

      if (spaceLeft < imgHeight) {
        pdf_config.addPage()
        finalY = 0
      }

      pdf_config.addImage(contentDataURL, 'PNG', 15, finalY + 3, imgWidth, imgHeight)

      if (e.target.name == enPdf.print) {
        pdf_config.autoPrint();
        window.open(pdf_config.output('bloburl'), '_blank');
      } else pdf_config.save(`${this.data_param_el}.pdf`);

    })
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
