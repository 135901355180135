<!-- SEARCH BAR -->

<div style="height:4em" class="row justify-content-end align-content-center">
<form id="wrap" action="" autocomplete="on">
  <input id="search" name="search" type="text" [(ngModel)]="search_text" placeholder="What're we looking for ?"><input
    id="search_submit" type="submit">
</form>
</div>
<div class="row mt-4">

  <div class="col-lg-12 col-xl-12 stretch-card">
    <div class="d-grid row flex-grow">

      <div class="col grid-margin stretch-card">
        <div class="card">
          <div class="d-flex justify-content-between card-header align-items-center mb-4 mb-md-3">
            <h6 class="card-title mb-0 ">Alarm Logs</h6>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th width="30%">Location</th>
                    <th width="20%">Device Id</th>
                    <th width="30%">Alarm Description</th>
                    <th width="20%">timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of tabular_data | filter:search_text">
                    <td>{{ data.location }}</td>
                    <td>{{ data.device_id }}</td>
                    <td>{{ data.alarm_descrip }}</td>
                    <td>{{ data.timestamp }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> <!-- row -->