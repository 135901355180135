<div class="row">
    <div class="col-md-12 mt-2 form-group mx-2">
        <a class="back-btn text-midnight" href="javascript:void(0);" [routerLink]="['/devices']"><img class="back-icon"
                src="./assets/media/icons/back-arrow.svg" />Back</a>
    </div>
</div>
<div class="row">
    <app-water-tank data="tankData"></app-water-tank>
    <div class="col-md-12 pt-4 mt-3">
        <h2 class="text-midnight font-weight-bold">{{ deviceDetail.device_name }} ( {{deviceDetail?.location}} )</h2>
    </div>
</div>

<div class="row mt-3 mb-5">
 
    <div class="col-lg-12 col-md-12 d-flex justify-content-end filters">
        <!-- <div class="select-filter">
        <img src="./assets/media/icons/calendar.svg" class="select-calendar ">
        <input [outsideDays]="outsideDays" [showWeekNumbers]="showWeekNumbers" [displayMonths]="2"  (click)="d2.toggle()" name="d2"
          #c2="ngModel" [(ngModel)]="filterStartDate" class="datepicker" ngbDatepicker #d2="ngbDatepicker"  readOnly>
      </div> -->

        <form class="form-inline d-inline-flex">
            <div class="form-group hidden">
                <div class="input-group">
                    <input name="datepicker" class="date-picker d-none" ngbDatepicker #datepicker="ngbDatepicker"
                        [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                        [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!">
                    <ng-template #t let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                            (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                        </span>
                    </ng-template>
                </div>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <input #dpFromDate class="date-picker" placeholder="yyyy-mm-dd" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                    <div class="input-group-append calendar-icon">
                        <button class="btn calendar border-0" (click)="datepicker.toggle()" type="button"><img
                                src="./assets/media/icons/calendar.svg"></button>
                    </div>
                </div>
            </div>
            <div class="form-group mx-2">
                <div class="input-group">
                    <input #dpToDate class="date-picker" placeholder="yyyy-mm-dd" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                    <div class="input-group-append calendar-icon">
                        <button class="btn calendar border-0" (click)="datepicker.toggle()" type="button"><img
                                src="./assets/media/icons/calendar.svg"></button>
                    </div>
                </div>
            </div>
        </form>


        <div class="select-filter">
            <img src="./assets/media/icons/filter.svg" class="select-filter">
            <select class="custom-select" [(ngModel)]="getlabel" (ngModelChange)="getLineGraph()">
                <option>Select Filter</option>
                <option *ngFor="let filter of filters">{{filter}}</option>
            </select>
        </div>



    </div>
</div>

<div class="row justify-content-center">
    <div class="col-lg-3 mb-5 widget" *ngFor='let params of paramDetail;let i = index'>
        <div class="card fun-blue-bg">
            <div class="card-body">
                <div class="d-flex justify-content-between">
                    <div class="mr-3 align-self-center"><img src="./assets/media/icons/temperature.svg">
                    </div>
                    <div class="align-self-center">
                        <h6 class="text-midnight mt-2 mb-3">{{params.description}}</h6>
                        <h2 class="text-downy">{{deviceInfo[i]}}</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="row mt-2 db-chart" id='graphGeneric'>
    <div *ngFor='let params of paramDetail;let i = index' class="col-lg-6 col-xl-6" id='{{params.parameter}}'>
        <div class="chart-card fun-blue-bg mb-4">
            <div class="chart-title" id="text1"> {{params.description}}
                <p class="mb-0"> </p>
                <button class="full-screen-btn" *ngIf="lineExpand == false" (click)="fullScreen(params.parameter)"><img
                        src="./assets/media/icons/expand.svg"></button>
                <button class="full-screen-btn" *ngIf="lineExpand == true" (click)="fullScreen(params.parameter)"><img
                        src="./assets/media/icons/minimize.svg"></button>
                <div ngbDropdown container="body" class="d-inline-block" placement="bottom-right">
                    <button tabindex="0" class="btn" id="cost-predicted" ngbDropdownToggle><img
                            src="./assets/media/icons/droplist.svg" alt="more"></button>
                    <div ngbDropdownMenu aria-labelledby="cost-predicted" class="dropdown-menu-right">
                        <button ngbDropdownItem (click)='generateCSV(lineChartData[i])'><img
                                src="./assets/media/icons/csv.svg" class="mr-2" alt="CSV" />Export
                            as
                            CSV</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem (click)='downloadPDF(params.parameter, lineChartData[i],$event)' name="Pdf">
                            <img src="./assets/media/icons/pdf.svg" class="mr-2" />Export as PDF</button>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem  (click)='downloadPDF(params.parameter, lineChartData[i],$event)' name="Print"><img src="./assets/media/icons/print.svg" class="mr-2"
                                alt="CSV" />Print</button>
                    </div>
                </div>
            </div>
            <div class="chart" style="padding-right: 10px; padding-top: 10px;">
                <!-- <canvas *ngIf='showGraph' baseChart [datasets]="lineChartData[i]" [labels]="filterArray[i].data"
                    [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                    [chartType]="'line'" height="200">
                </canvas> -->
            </div>
        </div>
    </div>


</div>