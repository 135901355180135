import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../shared/services/dashboard.service';
import { DataSharedService } from '../shared/services/data-shared.service';
import { ToastrService } from 'ngx-toastr';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas'

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  constructor(private dashboardService: DashboardService, private dataShared: DataSharedService, private toastr: ToastrService) { }

  public curr_tab: String = 'device-status';
  public tab_device_status: boolean = false;
  public tab_memory_status: boolean = false;
  public tab_internet_quality: boolean = false;
  public data_param:  String = '';
  public data_param_el: String = '';
  public data: any ='';
  public date: String = '';
  public parameters: any = ['internet_quality'];
  public memory: Number = 0;
  public allocate_memory: Number = 0;
  public devices = [];
  public device_location = [];
  public recent_activity: any = [];
  public device_activity = {
    'total': 0,
    'live': 0,
    'active': 0,
    'alert': 0
  };

  public selected_device = [];
  public dropdownSettings: IDropdownSettings;

  ngOnInit(): void {
    this.getDeviceLocation();
    this.getDashboardSettings();
    this.getDeviceActivity();
  }

  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);

  @ViewChild('status_body') status_body: ElementRef;

  getDashboardSettings() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {
      if (res.status == 200) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].tab_setting) : JSON.parse(config[0].sub_tab_setting);
        
        this.tab_device_status = data.allow_tab.status.device_status
        this.tab_memory_status = data.allow_tab.status.memory_status
        this.tab_internet_quality = data.allow_tab.status.internet_quality
      }
    })
  }

  tabChange($event: NgbNavChangeEvent) {

    this.curr_tab = $event.nextId;

    if (this.curr_tab == 'device-status') {

      this.getDeviceActivity();

    } else if (this.curr_tab == 'memory-status') {

      let body = {
        'action': 'getSettings',
        'meta_key': 'config',
        'user_id': localStorage.getItem('user'),
        'account_id': [localStorage.getItem('account')]
      };

      this.dashboardService.httpPost(body).subscribe((res: any) => {

        if (res.status == 200) {
          
          let config = res.data?.config;
          if (config) {
            let data = JSON.parse(config[0].meta_value);
            this.memory = (data.allow_disk_space) ? data.allow_disk_space : 0;
            this.allocate_memory = (res.data.allocated_memory) ? res.data.allocated_memory.replace('GB', '') : 0;
          }

        } else this.toastr.error('something went wrong');

      })
    } else if (this.curr_tab == 'internet-quality') {

      let body = {
        'action': 'getDevices',
        'account_id': localStorage.getItem('account')
      }

      this.dashboardService.httpPost(body).subscribe((res: any) => {

        if (res.data.length > 0) {

          this.devices = res.data;

          this.selected_device = [
            { device_id: this.devices[0].device_id, location: this.devices[0].location }
          ];

          this.dropdownSettings = {
            singleSelection: true,
            idField: 'device_id',
            textField: 'location',
            allowSearchFilter: true,
            clearSearchFilter: true,
            searchPlaceholderText: 'Find device',
            closeDropDownOnSelection: true
          };
          this.getCurrDate();
          this.getChartData();
        }

      });

    }

  };
  
  getDeviceLocation(){
    
    this.device_location = [];
    
    let body = {
      'action' : 'getDevices',
      'account_id': localStorage.getItem('account')
      }
      this.dashboardService .httpPost(body).subscribe((res: any) =>{
        
        res.data.forEach(element => {
          this.device_location.push({
            device_id: element.device_id,
            location: element.location
          })
        });
      })
  }

  getDeviceActivity() {
    
    this.resetParams(); let recent_activity = [];
    let body = {
      'action': 'getDeviceActivity',
      'user_id': localStorage.getItem('user'),
      'account_id': localStorage.getItem('account')
    };

    this.dashboardService.httpPost(body).subscribe((res: any) => {

      if (res.status == 200) {
        
        this.device_activity.total = res.data.total_devices;
        this.device_activity.live = res.data.activity.length;
   
        for (let i = 0; i < res.data.activity.length; i++ ) {

          for (let j = 0; j < this.device_location.length; j++ ) {
            
            if (res.data.activity[i].device_id == this.device_location[j].device_id) {
              recent_activity.push({
                device_id: res.data.activity[i].device_id,
                location: this.device_location[j].location,
                status: res.data.activity[i].status,
                last_received: res.data.activity[i].lastReceived
              });
            }
          }
        }

        res.data.activity.forEach(data => { 
      
          (data.status) ? this.device_activity.active++ : this.device_activity.alert++ 

        });

        this.recent_activity = recent_activity;

      } else this.toastr.error('something went wrong');

    })
    
  }

  getDataByParam() {
    return this.recent_activity;
 }

 exportAsPDF(e) {
   let data = [];
   if (data = this.getDataByParam()) this.dataShared.exportDataAsPdfFormat(data, e, this.data_param_el);
 }

  onDeviceSelect(item: any) {
    this.getChartData();
  }

  resetParams() {
    this.device_activity = { 'total': 0, 'live': 0, 'active': 0, 'alert': 0 };
  }

  getChartData() {
  
    let data, internet_quality_data = [];

    this.dashboardService.httpPost(this.getBody()).subscribe((res: any) => {

      if (res.data.buckets) {
        data = res.data.buckets[0].date_agg.buckets;

        for (let i = 0; i < data.length; i++) {

          internet_quality_data.push({
            date: new Date(data[i].key_as_string),
            value: data[i].val_internet_quality.value
          });

        }
      }

      this.implementLineChart(this.getGraphProp('internet', internet_quality_data));
    })
  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      title: '',
      data: param_data,
      interval: {
        'interval': 'minute',
        'value': 1
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: true
    };

    if (param == 'internet') {
      data.element = 'chartInternet'
      data.title = 'Ping Time (milli-second)'
    }

    return data;
  }

  implementLineChart(data) {

    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;
    
    chart.data = data.data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.baseInterval = {
      'timeUnit': data.interval.interval,
      'count': data.interval.value
    };

    dateAxis.tooltipDateFormat = data.tooltipFormat;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = data.title;
    // valueAxis.min = 0;

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.dateX = 'date';
    series.dataFields.valueY = 'value';
    series.tooltipText = 'Ping-rate: [bold]{valueY} ms';
    series.fillOpacity = 1;
    series.strokeWidth = 1;
    series.stroke = am4core.color("red");
    series.fill = am4core.color("red");

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;

    if (data.xScrollbar) {
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      // scrollbarX.scrollbarChart.seriesContainer.hide();
      chart.scrollbarX = scrollbarX;
    }

    dateAxis.start = 0;
    dateAxis.keepSelection = true;
    /* chart.svgContainer.autoResize = false;
    chart.svgContainer.measure(); */

  }

  getCurrDate() {
    let date = new Date();
    this.date = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
  }

  getBody() {
    return {
      'action': 'getChartDataByAccount',
      'user_id': [localStorage.getItem('user')],
      'device_id': [this.selected_device[0].device_id],
      'date': this.date,
      'fixed_interval': '1m',
      'parameters': this.parameters
    };
  }

  onDateSelection(e) {
    this.date = e.target.value
    this.getChartData();
  } 
  
  public makePdf(){ 
    
    let content= this.status_body.nativeElement;  
    let doc = new jsPDF('l', 'mm', 'A4'); 
     
    let _elementHandlers =  
    {  
      '#editor':function(element,renderer){  
        return true;  
      }  
    };  
    html2canvas(content).then((canvas)=>{
      doc.fromHTML(content, 15, 15, {pagesplit: true,
      StyleSheet:true,}, () => {
        doc.save('export.pdf');
      })});  
  }
}

