import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ThemeSideBar } from '../../common';
import { Router } from '@angular/router';
import { EmitterService } from 'src/app/shared/services/event-service';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
import { DashboardService } from 'src/app/shared/services/dashboard.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'zainergy-main-sidebar',
  templateUrl: './main-sidebar.component.html',
  styleUrls: ['./main-sidebar.component.scss']
})
export class MainSidebarComponent implements OnInit {

  constructor(private dashboardService: DashboardService, private router: Router, private eventEmitter: EmitterService, private dataShared: DataSharedService, private renderer: Renderer2, private authService: LoginService, private modalService: NgbModal) { }

  public menu_list: any[] = [];
  public company_logo = '';
  public company_logo_show: boolean = false;
  public active_menu: String = '';
  public active_child_menu: String = '';
  // public sidebar_menu_hierarchy: any;
  public toggleSidebar: Boolean = true;
  public closeResult;

  ngOnInit(): void {

    this.sideBarMenu();

    setTimeout(() => {
      if (this.dataShared.order_menu_list[this.router.url]) {
        this.dataShared.curr_menu = this.dataShared.order_menu_list[this.router.url];
        this.activeMenuClass(this.dataShared.curr_menu);
      }
    }, 100);

  }

  sideBarMenu() {

    let setting_body = {
      action: 'getSettings',
      meta_key: 'config',
      user_id: localStorage.getItem('user'),
      account_id: [localStorage.getItem('account')]
    }

    this.dashboardService.httpPost(setting_body).subscribe((res: any) => {

      let menu_list = [];

      if (res.status == 200 && localStorage.getItem('account')) {

        let config = res.data?.config;
        let data = (localStorage.getItem('sub_user') == '0') ? JSON.parse(config[0].dashboard_setting) : JSON.parse(config[0].sub_dashboard_setting);
       
        menu_list = [
          {
            "key": ThemeSideBar.dashboardKey,
            "name": data.parent_menu_1.value,
            "url": ThemeSideBar.dashboardUrl,
            "icon": ThemeSideBar.dashboardIcon,
            "status": data.parent_menu_1.status,
            "childrens": [{
              "key": ThemeSideBar.dashboardSingleKey,
              "name": data.parent_menu_1.chlid_menu_1?.value,
              "url": ThemeSideBar.dashboardSingleUrl,
              "status": (data.parent_menu_1.status) ? data.parent_menu_1.chlid_menu_1?.status : false
            },
            {
              "key": ThemeSideBar.dashboardCollectiveKey,
              "name": data.parent_menu_1.chlid_menu_2.value,
              "url": ThemeSideBar.dashboardCollectiveUrl,
              "status": (data.parent_menu_1.status) ? data.parent_menu_1.chlid_menu_2.status : false
            },
            {
              "key": ThemeSideBar.dashboardComparativeKey,
              "name": data.parent_menu_1.chlid_menu_3.value,
              "url": ThemeSideBar.dashboardComparativeUrl,
              "status": (data.parent_menu_1.status) ? data.parent_menu_1.chlid_menu_3.status : false
            }]
          },
          {
            "key": ThemeSideBar.analyticsKey,
            "name": data.parent_menu_2.value,
            "url": ThemeSideBar.analyticsUrl,
            "icon": ThemeSideBar.analyticsIcon,
            "status": data.parent_menu_2.status,
            "childrens": [{
              "key": ThemeSideBar.analysisEnergyKey,
              "name": data.parent_menu_2.chlid_menu_1.value,
              "url": ThemeSideBar.analysisEnergyUrl,
              "icon": ThemeSideBar.analyticsIcon,
              "status": (data.parent_menu_2.status) ? data.parent_menu_2.chlid_menu_1.status : false,
              "childrens": [{
                "key": ThemeSideBar.analysisEnergySingleKey,
                "name": data.parent_menu_2.chlid_menu_1.sub_chlid_1.value,
                "url": ThemeSideBar.analysisEnergySingleUrl,
                "status": (data.parent_menu_2.status && data.parent_menu_2.chlid_menu_1.status) ? data.parent_menu_2.chlid_menu_1.sub_chlid_1.status : false
              },
              {
                "key": ThemeSideBar.analysisEnergyCollectiveKey,
                "name": data.parent_menu_2.chlid_menu_1.sub_chlid_2.value,
                "url": ThemeSideBar.analysisEnergyCollectiveUrl,
                "status": (data.parent_menu_2.status && data.parent_menu_2.chlid_menu_1.status) ? data.parent_menu_2.chlid_menu_1.sub_chlid_2.status : false
              },
              {
                "key": ThemeSideBar.analysisEnergyComparativeKey,
                "name": data.parent_menu_2.chlid_menu_1.sub_chlid_3.value,
                "url": ThemeSideBar.analysisEnergyComparativeUrl,
                "status": (data.parent_menu_2.status && data.parent_menu_2.chlid_menu_1.status) ? data.parent_menu_2.chlid_menu_1.sub_chlid_3.status : false
              }]
            },
            {
              "key": ThemeSideBar.analysisPowerKey,
              "name": data.parent_menu_2.chlid_menu_2.value,
              "url": ThemeSideBar.analysisPowerUrl,
              "status": (data.parent_menu_2.status) ? data.parent_menu_2.chlid_menu_2.status : false
            },
            {
              "key": ThemeSideBar.analysisHeatMapKey,
              "name": data.parent_menu_2.chlid_menu_3.value,
              "url": ThemeSideBar.analysisHeatMapUrl,
              "status": (data.parent_menu_2.status) ? data.parent_menu_2.chlid_menu_3.status : false
            },
            {
              "key": ThemeSideBar.analysisShareKey,
              "name": data.parent_menu_2.chlid_menu_4.value,
              "url": ThemeSideBar.analysisShareUrl,
              "status": (data.parent_menu_2.status) ? data.parent_menu_2.chlid_menu_4.status : false
            },
            {
              "key": ThemeSideBar.analysisMinMaxKey,
              "name": data.parent_menu_2.chlid_menu_5.value,
              "url": ThemeSideBar.analysisMinMaxUrl,
              "status": (data.parent_menu_2.status) ? data.parent_menu_2.chlid_menu_5.status : false
            }]
          },
          {
            "key": ThemeSideBar.pvNetMeteringKey,
            "name": data.parent_menu_3.value,
            "url": ThemeSideBar.pvNetMeteringUrl,
            "icon": ThemeSideBar.pvNetMeteringIcon,
            "arrow": false,
            "status": data.parent_menu_3.status
          },
          {
            "key": ThemeSideBar.pvGenerationReportKey,
            "name": data.parent_menu_12?.value,
            "url": ThemeSideBar.pvGenerationReportUrl,
            "icon": ThemeSideBar.pvGenerationReportIcon,
            "arrow": false,
            "status": data.parent_menu_12?.status
          },
          {
            "key": ThemeSideBar.pvStringMonitoringKey,
            "name": data.parent_menu_4.value,
            "url": ThemeSideBar.pvStringMonitoringUrl,
            "icon": ThemeSideBar.pvStringMonitoringIcon,
            "arrow": false,
            "status": data.parent_menu_4.status
          },
          {
            "key": ThemeSideBar.buttonManagementKey,
            "name": (data.parent_menu_11) ? data.parent_menu_11.value : '',
            "url": ThemeSideBar.buttonManagementUrl,
            "icon": ThemeSideBar.buttonManagementIcon,
            "arrow": false,
            "status": (data.parent_menu_11) ? data.parent_menu_11.status : false
          }, 
          {
            "key": ThemeSideBar.chartsKey,
            "name": data.parent_menu_5.value,
            "url": ThemeSideBar.chartsUrl,
            "icon": ThemeSideBar.chartsIcon,
            "arrow": false,
            "status": data.parent_menu_5.status
          },
          {
            "key": ThemeSideBar.statusKey,
            "name": data.parent_menu_6.value,
            "url": ThemeSideBar.statusUrl,
            "icon": ThemeSideBar.statusIcon,
            "arrow": false,
            "status": data.parent_menu_6.status
          },
          {
            "key": ThemeSideBar.alarmKey,
            "name": data.parent_menu_7.value,
            "url": ThemeSideBar.alarmUrl,
            "icon": ThemeSideBar.alarmIcon,
            "arrow": false,
            "status": data.parent_menu_7.status
          },
          {
            "key": ThemeSideBar.favoriteKey,
            "name": data.parent_menu_8.value,
            "url": ThemeSideBar.favoriteUrl,
            "icon": ThemeSideBar.favoriteIcon,
            "arrow": false,
            "status": data.parent_menu_8.status
          },    
          {
            "key": ThemeSideBar.powerMonitoringKey,
            "name": data?.parent_menu_11 ? data.parent_menu_11.value: '',
            "url": ThemeSideBar.powerMonitoringUrl,
            "icon": ThemeSideBar.powerMonitoringIcon,
            "arrow": false,
            "status": data?.parent_menu_11 ? data.parent_menu_11.status : false
          },
          {
            "key": ThemeSideBar.billingSummaryKey,
            "name": data.parent_menu_9.value,
            "url": ThemeSideBar.billingSummaryUrl,
            "icon": ThemeSideBar.billingSummaryIcon,
            "arrow": false,
            "status": data.parent_menu_9.status
          },
          {
            "key": ThemeSideBar.settingsKey,
            "name": data.parent_menu_10.value,
            "url": ThemeSideBar.settingsUrl,
            "icon": ThemeSideBar.settingsIcon,
            "arrow": false,
            "status": data.parent_menu_10.status
          }
        ];
      } else {

        menu_list = [
          {
            "key": ThemeSideBar.customerManagmentKey,
            "name": ThemeSideBar.customerManagmentValue,
            "url": ThemeSideBar.customerManagmentUrl,
            "icon": ThemeSideBar.customerManagmentIcon,
            "status": true,
            "childrens": [
              {
              "key": ThemeSideBar.customerManagmentConfigurationKey,
              "name": ThemeSideBar.customerManagmentConfigurationValue,
              "url": ThemeSideBar.customerManagmentConfigurationUrl,
              "status": (localStorage.getItem('role') == '6') ? false : true
            },
            {
              "key": ThemeSideBar.customerManagmentDeviceDataStatusKey,
              "name": ThemeSideBar.customerManagmentDeviceDataStatusValue,
              "url": ThemeSideBar.customerManagmentDeviceDataStatusUrl,
              "status": true
            }
          ]
          }
        ];
      }

      for (let i = 0; i < menu_list.length; i++) {

        if (menu_list[i].status == true) {
          this.menu_list.push(menu_list[i]);
        }
      }
    });
  }

  activeMenuClass(menu) {
    this.dataShared.curr_menu = menu;
    let hide_panel_menu = ['customer-management~delete-user-data', 'customer-management~device-data-status', 'customer-management~configuration', 'customer-management~new-user', 'customer-management~user-setting', 'button-management', 'status', 'alarm-logs', 'favorite', 'favorite-summary', 'settings', 'pv-net-metering', 'pv-generation-report', 'billing-summary', 'power-monitoring'];
    let status = hide_panel_menu.indexOf(menu) > -1;
    this.eventEmitter.emit('menuFromPanelControl', status);
    this.eventEmitter.emit('menuForHeader', menu);
    // this.active_menu = this.router.url.includes(menu) ? menu : '';
  }

  activeChildMenuClass(child_menu) {
    // this.active_child_menu = this.router.url.includes(child_menu) ? child_menu : '';
  }

  toggleClass() {
    this.dataShared.toggleSidebar = this.toggleSidebar = !this.dataShared.toggleSidebar;
    // let toggle_class = (window.matchMedia('(min-width: 992px)').matches) ? 'sidebar-folded' : 'sidebar-open';
    let toggle_class = (window.matchMedia('(min-width: 992px)').matches) ? 'sidebar-folded' : 'open-sidebar';

    if (!this.dataShared.toggleSidebar) this.renderer.addClass(document.body, toggle_class);
    else this.renderer.removeClass(document.body, toggle_class);
  }

  openVerticallyCentered(content) {
		this.closeResult = this.modalService.open(content, { centered: true });
	}

  logOut() {
    this.closeResult.close();
    this.authService.logout()
  }
}
