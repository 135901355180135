import { Component, OnInit, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import { enPdf } from 'src/app/shared/constant';

@Component({
  selector: 'app-cylinder-gauge-chart',
  templateUrl: './cylinder-gauge-chart.component.html',
  styleUrls: ['./cylinder-gauge-chart.component.scss']
})
export class CylinderGaugeChartComponent implements OnInit {
  
  private chart: am4charts.XYChart;
  public data: any = [];

  public data_param_el: String = '';
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private dataShared: DataSharedService) {
  }

  ngOnInit(): void {
this.getSingleDeviceGraph();
  }
  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getSingleDeviceGraph() {
    // Chart code goes in here
    this.browserOnly(() => {

      this.getChartData();

    });
  }
  getChartData(){

    this.data =  [{
      "category": "2018 Q1",
      "value1": 30,
      "value2": 70
    }, {
      "category": "2018 Q2",
      "value1": 15,
      "value2": 85
    }, {
      "category": "2018 Q3",
      "value1": 40,
      "value2": 60
    }, {
      "category": "2018 Q4",
      "value1": 55,
      "value2": 45
    }];

    let prop = this.getGraphProp('data', this.data);

    this.implementChart(prop);

  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      title: '',
      data: param_data,
      interval: {
        'interval': 'minute',
        'value': 5
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };

    if (param == 'data') {
      data.element = 'cylinderchartdiv'
      data.title = 'OIL'
    }

    return data;
  }

  implementChart(data) {

    am4core.useTheme(am4themes_animated);

    // Create chart instance
let chart = am4core.create(data.element, am4charts.XYChart3D);
chart.paddingRight = 20;

// Add data
chart.data = data.data;

// Create axes
let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "category";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.grid.template.strokeOpacity = 0;

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.renderer.grid.template.strokeOpacity = 0;
valueAxis.min = -10;
valueAxis.max = 110;
valueAxis.strictMinMax = true;
valueAxis.renderer.baseGrid.disabled = true;
valueAxis.renderer.labels.template.adapter.add("text", function(text) {
  if ((parseInt(text) > 100) || (parseInt(text) < 0)) {
    return "";
  }
  else {
    return text + "%";
  }
})

// Create series
let series1 = chart.series.push(new am4charts.ConeSeries());
series1.dataFields.valueY = "value1";
series1.dataFields.categoryX = "category";
series1.columns.template.width = am4core.percent(80);
series1.columns.template.fillOpacity = 0.9;
series1.columns.template.strokeOpacity = 1;
series1.columns.template.strokeWidth = 2;

let series2 = chart.series.push(new am4charts.ConeSeries());
series2.dataFields.valueY = "value2";
series2.dataFields.categoryX = "category";
series2.stacked = true;
series2.columns.template.width = am4core.percent(80);
series2.columns.template.fill = am4core.color("#000");
series2.columns.template.fillOpacity = 0.1;
series2.columns.template.stroke = am4core.color("#000");
series2.columns.template.strokeOpacity = 0.2;
series2.columns.template.strokeWidth = 2;

  }

  exportAsCSV() {

    let data = this.data;

    if (data) {
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);

      let csv = data.map(row => header.map(field_name => JSON.stringify(row[field_name], replacer)).join(','));
      csv.unshift(header.join(',').toUpperCase());

      let csv_array = csv.join('\r\n');

      var blob = new Blob([csv_array], { type: 'text/csv' })
;
    }
  }

  exportAsPDF(e) {
    let data = this.data
    if (data) {

      const header = Object.keys(data[0]);
      let csv = []

      data.map((i) => {
        csv.push(Object.values(i))
      })

      let graph_header = []
      graph_header.push(header)
      let pdf_config = new jsPDF();
      pdf_config.setFontSize(18);
      pdf_config.text(`${this.data_param_el}`, 11, 8);
      pdf_config.setFontSize(11);
      pdf_config.setTextColor(100);

      (pdf_config as any).autoTable({
        head: graph_header,
        body: csv,
        theme: 'striped',
      })

      // generate graph on pdf
      this.generatePrintPdf(pdf_config, e)
    }
  }

  generatePrintPdf(pdf_config, e) {
    let finalY = (pdf_config as any).lastAutoTable.finalY;
    let data = document.getElementById(`${this.data_param_el}`)

    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 195;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pageHeight = pdf_config.internal.pageSize.height;

      let spaceLeft = pageHeight - finalY

      if (spaceLeft < imgHeight) {
        pdf_config.addPage()
        finalY = 0
      }

      pdf_config.addImage(contentDataURL, 'PNG', 15, finalY + 3, imgWidth, imgHeight)

      if (e.target.name == enPdf.print) {
        pdf_config.autoPrint();
        window.open(pdf_config.output('bloburl'), '_blank');
      } else pdf_config.save(`${this.data_param_el}.pdf`);

    })
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}
