import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DhtGraphComponent } from './dht-graph-component/dht-graph-component'
import { WaterTankComponent } from './water-tank/water-tank.component'
// import { GaugeModule } from 'angular-gauge';
// import { ChartsModule } from 'ng2-charts';
import { BarGraphComponent } from './bar-graph/bar-graph.component';
import { GaugeGraphComponent } from './gauge-graph/gauge-graph.component';
import { DoughnutGraphComponent } from './doughnut-graph/doughnut-graph.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LineGraphComponent } from './line-graph/line-graph.component';
import { CardsComponent } from '../components/cards/cards.component';
import { FormsModule } from '@angular/forms';
import { BarchartVariamceIndicatorsComponent } from './barchart-variamce-indicators/barchart-variamce-indicators.component';
import { LinechartDataGroupingComponent } from './linechart-data-grouping/linechart-data-grouping.component';
import { ChartLiveDataComponent } from './chart-live-data/chart-live-data.component';
import { ChartWaterTankComponent } from './chart-water-tank/chart-water-tank.component';
import { CylinderGaugeChartComponent } from './cylinder-gauge-chart/cylinder-gauge-chart.component';
import { ColumnLineChartComponent } from './column-line-chart/column-line-chart.component';
import { HeatMapChartComponent } from './heat-map-chart/heat-map-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { AmchartsComponent } from './amcharts/amcharts.component';
import { ApiFunctionsComponent } from './api-functions/api-functions.component';
import { CountdownComponent } from './countdown/countdown.component';

@NgModule({
  declarations: [
    DhtGraphComponent,
    WaterTankComponent,
    BarGraphComponent,
    GaugeGraphComponent,
    DoughnutGraphComponent,
    LineGraphComponent,
    CardsComponent,
    BarchartVariamceIndicatorsComponent,
    LinechartDataGroupingComponent,
    ChartLiveDataComponent,
    ChartWaterTankComponent,
    CylinderGaugeChartComponent,
    ColumnLineChartComponent,
    HeatMapChartComponent,
    PieChartComponent,
    AmchartsComponent,
    ApiFunctionsComponent,
    CountdownComponent
  ],
  imports: [
    CommonModule,
    // GaugeModule,
    // ChartsModule,
    NgbModule,
    FormsModule
  ],
  exports: [
    DhtGraphComponent,
    WaterTankComponent,
    BarGraphComponent,
    GaugeGraphComponent,
    DoughnutGraphComponent,
    LineGraphComponent,
    CardsComponent,
    BarchartVariamceIndicatorsComponent,
    LinechartDataGroupingComponent,
    ChartLiveDataComponent,
    ChartWaterTankComponent,
    CylinderGaugeChartComponent,
    ColumnLineChartComponent,
    HeatMapChartComponent,
    PieChartComponent,
    AmchartsComponent,
    ApiFunctionsComponent,
    CountdownComponent
  ]
})
export class WidgetsModule { }