<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <!-- <h4 class="card-title">Bar Sales Chart</h4>
                <h6 class="card-subtitle">This is the simple example of bar chart</h6> -->
                <div  id="chartdiv">
                    <!-- <canvas  baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend" [chartType]="barChartType" (chartHover)="chartHovered($event)" [colors]="barChartColors" (chartClick)="chartClicked($event)">
                    </canvas> -->
                </div>
            </div>
            <div class="card-footer">
            </div>
        </div>
    </div>
</div>
