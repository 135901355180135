<div class="row mt-3 mb-5">
    

    <div class="col-lg-12 col-md-12 d-flex justify-content-end filters">
        <div class="select-filter">
            <img src="./assets/media/icons/filter.svg" class="select-filter">
            <select class="custom-select" [(ngModel)]="deviceSeleced" (change)="getDeviceType()"  >
                <option>All</option>
                <option  *ngFor='let devices of fileredDevices' [value]='devices.id' >{{ devices.device_name }}</option>
            </select>
        </div>
        <div class="select-filter">
            <img src="./assets/media/icons/filter.svg" class="select-filter">
            <select class="custom-select" [(ngModel)]="deviceCategories" (change)="getSimilarDevice()"  >
                <option>All</option>
                <option  *ngFor='let sensor of sensorData' [value]="sensor.id">{{ sensor.value }}</option>
            </select>
        </div>
        <form class="form-inline d-inline-flex">

            <div class="form-group hidden">
                <div class="input-group">
                    <input name="datepicker" class="date-picker d-none" ngbDatepicker #datepicker="ngbDatepicker"
                        [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2"
                        [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!">
                    <ng-template #t let-date let-focused="focused">
                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                            [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                            (mouseleave)="hoveredDate = null">
                            {{ date.day }}
                        </span>
                    </ng-template>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    <input #dpFromDate class="date-picker" placeholder="yyyy-mm-dd" name="dpFromDate"
                        [value]="formatter.format(fromDate)"
                        (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                    <div class="input-group-append calendar-icon">
                        <button class="btn calendar border-0" (click)="datepicker.toggle()" type="button"><img
                                src="./assets/media/icons/calendar.svg"></button>
                    </div>
                </div>
            </div>

            <div class="form-group mx-2">
                <div class="input-group">
                    <input #dpToDate class="date-picker" placeholder="yyyy-mm-dd" name="dpToDate"
                        [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
                    <div class="input-group-append calendar-icon">
                        <button class="btn calendar border-0" (click)="datepicker.toggle()" type="button"><img
                                src="./assets/media/icons/calendar.svg"></button>
                    </div>
                </div>
            </div>
        </form>

        <div class="select-filter">
            <img src="./assets/media/icons/filter.svg" class="select-filter">
            <select class="custom-select" [(ngModel)]="getlabel" (change)="durationFilter()">
                <option>Select Filter</option>
                <option *ngFor="let filter of filters">{{ filter }}</option>
            </select>
        </div>


    </div>
</div>