<app-dht-graph [lineGraph]='graphArray'></app-dht-graph>
<!-- <div class="row mt-3 db-chart" id='graphGeneric'>
    <div class="col-lg-4 mb-4 widget">
        <div class="card fun-blue-bg">
            <div class="card-body">
                <div class="d-flex">
                    <app-bar-graph *ngIf='barGaphData != undefined' [barGraph]='barGaphData'></app-bar-graph>
                    <div class="circle-chart">
                        <app-gauge-graph [gaugeData]='gauge'></app-gauge-graph>         

            </div>
        </div>
    </div>
</div>
</div>
</div> -->
