import { Component, OnInit, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
// amCharts imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import { enPdf } from 'src/app/shared/constant';

@Component({
  selector: 'app-chart-water-tank',
  templateUrl: './chart-water-tank.component.html',
  styleUrls: ['./chart-water-tank.component.scss']
})
export class ChartWaterTankComponent implements OnInit {
  private chart: am4charts.XYChart;
  public data: any = [];
  public data_param_el: String = '';

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private dataShared: DataSharedService) {
  }

  ngOnInit(): void {
    this.getSingleDeviceGraph();
  }
  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getSingleDeviceGraph() {
    // Chart code goes in here
    this.browserOnly(() => {

      this.getChartData();

    });
  }
  getChartData() {
    this.data = [{ 
      "capacity" :  6000,
      "value" : 4000,
      "circleSize" : 0.8
    },{ 
      "capacity" :  6000,
      "value" : 4000,
      "circleSize" : 0.8
    },{ 
      "capacity" :  6000,
      "value" : 4000,
      "circleSize" : 0.8
    }]

    let prop = this.getGraphProp('data', this.data);

    this.implementChart(prop);

  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      title: '',
      data: param_data,
      interval: {
        'interval': 'minute',
        'value': 5
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };

    if (param == 'data') {
      data.element = 'tankchartdiv'
      data.title = 'BAR'
    }

    return data;
  }

  implementChart(data) {

    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let component = am4core.create(data.element, am4core.Container);

    component.width = am4core.percent(100);
    component.height = am4core.percent(100);

    let chartContainer = component.createChild(am4core.Container);
    chartContainer.x = am4core.percent(50)
    chartContainer.y = am4core.percent(50)

    let circle = chartContainer.createChild(am4core.Circle);
    circle.fill = am4core.color("#dadada");

    let circleMask = chartContainer.createChild(am4core.Circle);

    let waves = chartContainer.createChild(am4core.WavedRectangle);
    waves.fill = am4core.color("#34a4eb");
    waves.mask = circleMask;
    waves.horizontalCenter = "middle";
    waves.waveHeight = 10;
    waves.waveLength = 30;
    waves.y = 500;
    circleMask.y = -500;

    component.events.on("maxsizechanged", function () {
      let smallerSize = Math.min(component.pixelWidth, component.pixelHeight);
      let radius = smallerSize * data.data[0].circleSize / 2;

      circle.radius = radius;
      circleMask.radius = radius;
      waves.height = smallerSize;
      waves.width = Math.max(component.pixelWidth, component.pixelHeight);

      //capacityLabel.y = radius;

      let labelRadius = radius + 20

      capacityLabel.path = am4core.path.moveTo({ x: -labelRadius, y: 0 }) + am4core.path.arcToPoint({ x: labelRadius, y: 0 }, labelRadius, labelRadius);
      capacityLabel.locationOnPath = 0.5;

      setValue(data.data[0].value);
    })


    function setValue(value) {
      let y = - circle.radius - waves.waveHeight + (1 - value / data.data[0].capacity) * circle.pixelRadius * 2;
      waves.animate([{ property: "y", to: y }, { property: "waveHeight", to: 10, from: 15 }, { property: "x", from: -50, to: 0 }], 5000, am4core.ease.elasticOut);
      circleMask.animate([{ property: "y", to: -y }, { property: "x", from: 50, to: 0 }], 5000, am4core.ease.elasticOut);
    }


    let label = chartContainer.createChild(am4core.Label)
    let formattedValue = component.numberFormatter.format(data.data[0].value, "#.#a");
    formattedValue = formattedValue.toUpperCase();

    label.text = formattedValue + " Litres";
    label.fill = am4core.color("#fff");
    label.fontSize = 30;
    label.horizontalCenter = "middle";


    let capacityLabel = chartContainer.createChild(am4core.Label)

    let formattedCapacity = component.numberFormatter.format(data.data[0].capacity, "#.#a").toUpperCase();;

    capacityLabel.text = "Capacity " + formattedCapacity + " Litres";
    capacityLabel.fill = am4core.color("#34a4eb");
    capacityLabel.fontSize = 20;
    capacityLabel.textAlign = "middle";
    capacityLabel.padding(0, 0, 0, 0);

  }

  exportAsCSV() {

    let data = this.data;

    if (data) {
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);

      let csv = data.map(row => header.map(field_name => JSON.stringify(row[field_name], replacer)).join(','));
      csv.unshift(header.join(',').toUpperCase());

      let csv_array = csv.join('\r\n');

      var blob = new Blob([csv_array], { type: 'text/csv' })

     
    }
  }
exportAsPDF(e) {
  let data = this.data
 
  if (data) {

    const header = Object.keys(data[0]);
    let csv = []
   
    data.map((i) => {
      csv.push(Object.values(i))
    })

    let graph_header = []
    graph_header.push(header)
    let pdf_config = new jsPDF();
    pdf_config.setFontSize(18);
    pdf_config.text(`${this.data_param_el}`, 11, 8);
    pdf_config.setFontSize(11);
    pdf_config.setTextColor(100);

    (pdf_config as any).autoTable({
      head: graph_header,
      body: csv,
      theme: 'striped',
    })

    // generate graph on pdf
    this.generatePrintPdf(pdf_config, e)
  }
}

generatePrintPdf(pdf_config, e) {
  let finalY = (pdf_config as any).lastAutoTable.finalY;
  let data = document.getElementById(`${this.data_param_el}`)

  html2canvas(data).then(canvas => {
    // Few necessary setting options  
    var imgWidth = 195;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;

    const contentDataURL = canvas.toDataURL('image/png')
    let pageHeight = pdf_config.internal.pageSize.height;

    let spaceLeft = pageHeight - finalY

    if (spaceLeft < imgHeight) {
      pdf_config.addPage()
      finalY = 0
    }

    pdf_config.addImage(contentDataURL, 'PNG', 15, finalY + 3, imgWidth, imgHeight)

    if (e.target.name == enPdf.print) {
      pdf_config.autoPrint();
      window.open(pdf_config.output('bloburl'), '_blank');
    } else pdf_config.save(`${this.data_param_el}.pdf`);

  })
}

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
