import { Component } from '@angular/core';
import { LoginService } from '../../shared/services/login.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  
  emailId: any;
  password: any;
  showError = false;
  submitted = false;
  emailPrompt: boolean;
  passwordPrompt: boolean;
  forgotPassword = false
  emailRememberPrompt = false
  reqiureRegister = false
  public eye: any = 'feather-eye-off'

  constructor(private authService: LoginService) { }

  validate() {
    if (!this.emailId) {
      this.emailPrompt = true
    }
    else {
      this.emailPrompt = false
    }
    if (!this.password) {
      this.passwordPrompt = true
    }
    else {
      this.passwordPrompt = false
    }
    return !this.passwordPrompt && !this.emailPrompt
  }

  login() {
    this.showError = false
    if (this.validate()) {

      let body = {
        email: this.emailId,
        password: this.password
      }

      this.authService.login(body).subscribe((res: any) => {
        /* if(res.data.user) {
          this.authService.getUserAccount();
        } */
      },
        error => {
          this.showError = true
        })
    }
  }

  Input() {
    const pass_input = document.getElementById('password') as HTMLInputElement;
    if (pass_input.type == 'password') {
      pass_input.type = 'text';
      this.eye = 'feather-eye';
    } else {
      pass_input.type = 'password';
      this.eye = 'feather-eye-off';
    }
  }
}
