<div>
    <div>
      <div style="display: block">
        <!-- <canvas baseChart
          [data]="doughnutData"
          [labels]="doughnutChartLabels"
          [chartType]="doughnutChartType">
        </canvas> -->
      </div>
    </div>
  </div>