<section class="h-100 gradient-form" style="background-color: #f1f1f1;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <div class="col-lg-6">
              <div class="card-body p-md-5 mx-md-4">

                <div class="text-center">
                  <img src="../../../assets/media/teb-logo.png" style="width: 185px;" alt="logo">
                  <h4 class="mt-1 mb-5 pb-1">Empowering Your World with IoT</h4>
                </div>

                <form>
                  <p>Please login to your account</p>

                  <div data-mdb-input-init class="form-outline mb-4">
                    <input type="email" id="form2Example11" class="form-control" [ngClass]="{'val-border': emailPrompt || showError}" [(ngModel)]="emailId"
                    [ngModelOptions]="{standalone: true}" placeholder="Phone number or email address" />
                      <small class="error" *ngIf="emailPrompt"> Email is Required!</small>
                  </div>

                  <div class="password-con">
                    <div data-mdb-input-init class="form-outline d-flex align-items-center">
                      <input type="password" id="password" class="form-control border-0" [ngClass]="{'val-border': passwordPrompt || showError}" [(ngModel)]="password" 
                      [ngModelOptions]="{standalone: true}" placeholder="password" />
                      <i (click)="Input()" style="align-items: center; width: 10%; height: 10%; z-index: 1;" class="{{eye}}"></i>
                    </div>
                  </div>
                  <small class="error" *ngIf="passwordPrompt"> Password is Required!</small>

                  <i class="error" *ngIf="showError">Invalid Username or Password!</i>

                  <div class="text-center pt-1 mb-5 mt-4 pb-1">
                    <button data-mdb-button-init data-mdb-ripple-init
                      class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3 w-100" type="button" (click)="login()">Log
                      in</button>
                  </div>

                </form>

              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center second-bg">
              <div class="text-white px-3 py-4 p-md-5 mx-md-4">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>