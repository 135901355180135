<div class="card" id="line_chart">
    <div class="d-flex justify-content-between card-header align-items-center">
        <h6 class="card-title mb-0 ">Data Grouping Line Chart</h6>

        <div class="header-right d-flex align-items-center">

            <span class="divider"></span>

            <i class="icon-sm card-header-icon ml-4 pb-3px maximize-card feather feather-maximize-2"
                                    (click)="toggleCard($event)"></i>
                                    <div class="dropdown ml-2">
                                        <button class="btn p-0" type="button" id="dropdownMenuButton3" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                            <i class="icon-sm pb-3px card-header-icon feather feather-more-vertical"></i>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                                            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Print"
                                                (click)="data_param_el = 'line_chart'; exportAsPDF($event)">
                                                <i class="feather feather-printer icon-sm mr-2"></i>
                                                <span class="">Print</span>
                                            </a>
                                            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);"
                                                (click)="data_param_el = 'line_chart'; exportAsCSV()">
                                                <i class="feather feather-download icon-sm mr-2"></i>
                                                <span class="">Export as CSV</span>
                                            </a>
                                            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0);" name="Pdf"
                                                (click)="data_param_el = 'line_chart'; exportAsPDF($event)">
                                                <i class="feather feather-file-text icon-sm mr-2"></i>
                                                <span class="">Export as PDF</span>
                                            </a>
                                        </div>
                                    </div>
        </div>

    </div>
    <div class="card-body">
        <div class="d-flex align-items-center justify-content-end">
        </div>
        <div class="row align-items-start mb-2">
        </div>

        <div class="flot-wrapper" style="margin-top: -15px;">
            <div id="chartdiv" style="width: 100%; height: 450px"></div>
        </div>
    </div>
</div>