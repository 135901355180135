import { Component, OnInit, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DataSharedService } from 'src/app/shared/services/data-shared.service';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import { enPdf } from 'src/app/shared/constant';

@Component({
  selector: 'app-barchart-variamce-indicators',
  templateUrl: './barchart-variamce-indicators.component.html',
  styleUrls: ['./barchart-variamce-indicators.component.scss']
})
export class BarchartVariamceIndicatorsComponent implements OnInit {
  private chart: am4charts.XYChart;
  public data: any = [];
  public data_param_el: String = '';
  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone, private dataShared: DataSharedService) {
  }

  ngOnInit(): void {
    this.getSingleDeviceGraph();
  }

  toggleCard = (e) => this.dataShared.toggleCardFullsreen(e);
  
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  getSingleDeviceGraph() {
    // Chart code goes in here
    this.browserOnly(() => {

      this.getChartData();

    });
  }
  getChartData() {

    this.data = [{
      "year": "2011",
      "value": 600000
    }, {
      "year": "2012",
      "value": 900000
    }, {
      "year": "2013",
      "value": 180000
    }, {
      "year": "2014",
      "value": 600000
    }, {
      "year": "2015",
      "value": 350000
    }, {
      "year": "2016",
      "value": 600000
    }, {
      "year": "2017",
      "value": 670000
    }];

    let prop = this.getGraphProp('data', this.data);

    this.implementChart(prop);

  }

  getGraphProp(param, param_data) {

    let data = {
      element: '',
      title: '',
      data: param_data,
      interval: {
        'interval': 'minute',
        'value': 5
      },
      tooltipFormat: 'HH:mm:ss, d MMMM',
      xScrollbar: false
    };

    if (param == 'data') {
      data.element = 'bardiv'
      data.title = 'BAR'
    }

    return data;
  }

  implementChart(data) {

    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create(data.element, am4charts.XYChart);
    chart.paddingRight = 20;

    // Add data
    chart.data = data.data;

    // Populate data
    for (var i = 0; i < (chart.data.length - 1); i++) {
      chart.data[i].valueNext = chart.data[i + 1].value;
    }

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "year";

    // Add series for showing variance arrows
    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "valueNext";
    series2.dataFields.openValueY = "value";
    series2.dataFields.categoryX = "year";
    series2.columns.template.width = 1;
    series2.fill = am4core.color("#555");
    series2.stroke = am4core.color("#555");

    // Add a triangle for arrow tip
    let arrow = series2.bullets.push(new am4core.Triangle);
    arrow.width = 10;
    arrow.height = 10;
    arrow.horizontalCenter = "middle";
    arrow.verticalCenter = "top";
    arrow.dy = -1;

    // Set up a rotation adapter which would rotate the triangle if its a negative change
    arrow.adapter.add("rotation", function (rotation, target) {
      return getVariancePercent(target.dataItem) < 0 ? 180 : rotation;
    });

    // Set up a rotation adapter which adjusts Y position
    arrow.adapter.add("dy", function (dy, target) {
      return getVariancePercent(target.dataItem) < 0 ? 1 : dy;
    });

    // Add a label
    let label = series2.bullets.push(new am4core.Label);
    label.padding(10, 10, 10, 10);
    label.text = "";
    label.fill = am4core.color("#0c0");
    label.strokeWidth = 0;
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
    label.fontWeight = "bolder";

    // Adapter for label text which calculates change in percent
    label.adapter.add("textOutput", function (text, target) {
      let percent = getVariancePercent(target.dataItem);
      return percent ? percent + "%" : text;
    });

    // Adapter which shifts the label if it's below the variance column
    label.adapter.add("verticalCenter", function (center, target) {
      return getVariancePercent(target.dataItem) < 0 ? "top" : center;
    });

    // Adapter which changes color of label to red
    label.adapter.add("fill", function (fill, target) {
      return getVariancePercent(target.dataItem) < 0 ? am4core.color("#c00") : fill;
    });

    function getVariancePercent(dataItem) {
      if (dataItem) {
        let value = dataItem.valueY;
        let openValue = dataItem.openValueY;
        let change = value - openValue;
        return Math.round(change / openValue * 100);
      }
      return 0;
    }
  }

  exportAsCSV() {

    let data = this.data;

    if (data) {
      const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
      const header = Object.keys(data[0]);

      let csv = data.map(row => header.map(field_name => JSON.stringify(row[field_name], replacer)).join(','));
      csv.unshift(header.join(',').toUpperCase());

      let csv_array = csv.join('\r\n');

      var blob = new Blob([csv_array], { type: 'text/csv' })


    }
  }

  exportAsPDF(e) {
    let data = this.data
    if (data) {

      const header = Object.keys(data[0]);
      let csv = []

      data.map((i) => {
        csv.push(Object.values(i))
      })

      let graph_header = []
      graph_header.push(header)
      let pdf_config = new jsPDF();
      pdf_config.setFontSize(18);
      pdf_config.text(`${this.data_param_el}`, 11, 8);
      pdf_config.setFontSize(11);
      pdf_config.setTextColor(100);

      (pdf_config as any).autoTable({
        head: graph_header,
        body: csv,
        theme: 'striped',
      })

      // generate graph on pdf
      this.generatePrintPdf(pdf_config, e)
    }
  }

  generatePrintPdf(pdf_config, e) {
    let finalY = (pdf_config as any).lastAutoTable.finalY;
    let data = document.getElementById(`${this.data_param_el}`)

    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 195;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pageHeight = pdf_config.internal.pageSize.height;

      let spaceLeft = pageHeight - finalY

      if (spaceLeft < imgHeight) {
        pdf_config.addPage()
        finalY = 0
      }

      pdf_config.addImage(contentDataURL, 'PNG', 15, finalY + 3, imgWidth, imgHeight)

      if (e.target.name == enPdf.print) {
        pdf_config.autoPrint();
        window.open(pdf_config.output('bloburl'), '_blank');
      } else pdf_config.save(`${this.data_param_el}.pdf`);

    })
  }

  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
