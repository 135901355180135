<div class="row mt-4">
    <div class="col-12 col-xl-12 stretch-card">
        <div class="row flex-grow">

            <div class="card-row-adjust solar-card col-sm-6 col-lg grid-margin stretch-card" *ngFor="let kpi of kpis">
                <div class="card text-white" style="background-color: #a15858db">
                    <div class="row h-100">
                        <div class="col-12">
                            <div class="mr-2 d-flex float-right">
                                <i *ngIf="kpi.icon" class="{{ kpi.icon }}"></i>
                                <img *ngIf="kpi.icon_img" src="{{ kpi.icon_img }}">
                            </div>
                            <h4 class="ml-4 mt-2" style="text-align: start;">{{ kpi.value | currency: ' ' }} {{kpi.unit}}</h4>
                            <h6 class="card-title ml-4 text-left text-white">{{
                                kpi.key | titlecase }}</h6>
                            </div>
                            <div class="col-12" style="margin-top: auto; text-align: center;">
                            <div class="card-footer text-center text-white" style="
                                font-size: x-small; 
                                background-color: rgba(4, 105, 187, 0.445);
                                padding: 3px !important;" *ngIf="timestamp != ''">
                                Last Updated: {{timestamp}}</div>
                        </div>
                    </div>

                </div>
                <div class="card-icons" *ngIf="kpi.button_icon" class="{{ kpi.button_icon.class }}">
                    <span>{{ kpi.button_icon.symbol }}</span>
                </div>
            </div>

            <!-- <div class="col grid-margin stretch-card" *ngFor="let kpi of kpis">
                <div class="card">
                    <div class="card-body">
                        <div class="row m-0">
                            <div class="col-12 p-0">
                                <div class="mr-2 d-flex float-left">
                                    <i *ngIf="kpi.icon" class="{{ kpi.icon }}"></i>
                                    <img *ngIf="kpi.icon_img" src="{{ kpi.icon_img }}">
                                </div>
                                <h3 class="mb-0" style="text-align: center;">{{ kpi.value | currency: ' ' }}</h3>
                                <h6 class="card-title mb-0 mt-2 echo-blue-text"
                                    style="color: red !important; text-align: center;">{{ kpi.key }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="kpi.button_icon" class="{{ kpi.button_icon.class }}">
                    <span>{{ kpi.button_icon.symbol }}</span>
                </div>
            </div> -->


        </div>
    </div>
</div>
<!-- 
<div class="col-sm-6 col-lg grid-margin stretch-card" *ngFor="let kpi of kpis">
    <div class="card text-white" style="background-color: #5DADE2">
        <div class="row">
            <div class="col-12">
                <div class="mr-2 d-flex float-right">
                    <i *ngIf="kpi.icon" class="{{ kpi.icon }}"></i>
                    <img *ngIf="kpi.icon_img" src="{{ kpi.icon_img }}">>
                </div>
                <h4 class="ml-4 mt-2" style="text-align: start;">{{ kpi.value | currency: ' ' }}</h4>
                <h6 class="card-title ml-4" style="color: white !important; text-align: start;">{{
                    kpi.key }}</h6>

                <div class="card-footer" style="
                    color: rgb(255, 255, 255); 
                    font-size: x-small; 
                    text-align: center; 
                    background-color: rgba(4, 105, 187, 0.445);
                    padding: 3px !important;" *ngIf="timestamp != ''">
                    Last Updated: {{timestamp}}</div>

            </div>
        </div>

    </div>
    <div *ngIf="kpi.button_icon" class="{{ kpi.button_icon.class }}">
        <span>{{ kpi.button_icon.symbol }}</span>
    </div>
</div> -->